/* You can add global styles to this file, and also import other style files */
html, body {
  margin: 0;
  padding: 0;
  background-color: #f1f2f6 !important;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

.menu-content.h-100 {
  background-color: #f1f2f6;
}

li:hover {
  cursor: pointer;
}

a:hover {
  cursor: pointer;
}
.swal2-confirm{
  width:103px
}
.sticky-div {
  z-index: 998;
  position: sticky;
  top: 60px;
  background: #f5f5f9;
  border-radius: 3px;
  margin-bottom: 9px;
  padding-left: 10px;
}

.swal2-styled {
  width: 100px;
}

.form-style {
  margin-left: 250px;
}

/* CAN (MUST) BE REMOVED ! Sample Global style */
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: url(./assets/background.jpg) no-repeat center fixed;
  -webkit-background-size: cover; /* pour anciens Chrome et Safari */
  background-size: cover; /* version standardisée */

  .title {
    color: white;
    margin: 0;
    padding: 50px 20px;
  }

  a {
    color: #fff !important;
    text-transform: uppercase;
    text-decoration: none;
    background: #ed3330;
    padding: 20px;
    border-radius: 5px;
    display: inline-block;
    border: none;
    transition: all 0.4s ease 0s;

    &:hover {
      background: #fff;
      color: #ed3330 !important;
      letter-spacing: 1px;
      -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
      -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
      box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
      transition: all 0.4s ease 0s;
    }
  }
}

.btn {
  margin: 5px;
}

.add-button-class {
  margin-bottom: 9px;
  margin-left: 402px;
  margin-top: -33px;
}

.button-items {
  //padding-bottom: 20px !important;

  .line {
    font-size: 30px;
    padding: 0;
  }

  button {
    border: none;
    //width: 100px !important;
  }

  i {
    font-size: 2rem !important;
    font-weight: lighter;
  }

  .btn:disabled {
    border-color: grey;
    color: grey;
  }

}


.page-title {
  font-weight: normal;
  text-transform: uppercase;
  font-size: 20px;
}

.page-title-box {
  padding: 5px 0 !important;
}

.wrning-tag {
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: #ef4d56;
  //display: none;
}

.preview-data {
  .preview-div {
    margin-left: 11px;
  }

  .table {
    padding: 0.4rem .4em;
  }

  .info-section {
    padding-left: 10px;
  }

  .title {
    font-weight: bold !important;
    color: grey !important;
    font-size: 8pt !important;
  }

  div, p {
    font-size: 8pt;
    font-weight: 300;
  }

  .card {
    margin-bottom: 5px;
  }

  .card-header {
    padding-bottom: 0;
    padding-top: 0;
  }


  span.bold {
    font-size: 8pt;
    font-weight: lighter;
    text-transform: uppercase;
  }


  //Preview Panel Css
  .preview-title {
    color: grey;
    padding: 0;
    margin: 0;
    font-size: .8125rem;
    font-weight: 400;
    text-transform: uppercase;
  }

  .preview-title-examinations {
    //margin-left: 16px;
    margin-bottom: -2px;
  }

  tr.ng-star-inserted {
    font-weight: 500 !important;
    font-size: 8pt;
  }

}

//separate scroll css

.scroll {
  height: calc(100vh - 80px);
  overflow-y: auto;
}


//css related to sideview of dialogs
.autocomplete-container {
  width: 70% !important;
}

.cdk-overlay-container {
  left: 10%;
  transform: translateX(-7%);
  width: 49%;
}

.mat-dialog-container {
  width: 80% !important;
  margin: auto;
}

.control-button {
  .btn {
    border: none;

    i {
      font-size: 1.5rem;
    }

    .main {
      color: whitesmoke;
    }
  }
}

div#cdk-overlay-1, div#cdk-overlay-3,
div#cdk-overlay-5, div#cdk-overlay-7,
div#cdk-overlay-9, div#cdk-overlay-11 {
  left: 12.5% !important;
}

div#cdk-overlay-2, div#cdk-overlay-4,
div#cdk-overlay-8, div#cdk-overlay-6,
div#cdk-overlay-10, div#cdk-overlay-12 {
  left: 50.5% !important;
}

.cal-events.ng-star-inserted {
  display: none !important;
}
